

























import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private qrsrc: any = "";
  private text: any = "";
  private mounted() {
    this.qrsrc = window.qrsrc;
    this.text = document.title;
  }
}
